<template>
  <footer>
    <a class="email" :href="`mailto:${config.social.email_address}`" target="_blank"><sui-icon name="envelope" /></a>
    <a class="linkedin" :href="`https://www.linkedin.com/in/${config.social.linkedin_handle}`" target="_blank"><sui-icon name="linkedin" /></a>
    <a class="github" :href="`https://github.com/${config.social.github_handle}`" target="_blank"><sui-icon name="github" /></a>
  </footer>
</template>
<script>
import Social from '@/config/social.json'

export default {
  name: 'Footer',
  data: () => {
    return {
      config: {
        social: Social
      }
    }
  }
}
</script>
<style>
footer {
  margin-top: 40px;
  padding-bottom: 40px;
}

footer > a  {
  font-size: 20px;
  text-decoration: none;
  color: #b9b9b9;
}

footer > a:hover {
  color: #000000 !important;
  transition: color 0.3s ease;
}

footer > a:hover > .linkedin.icon {
  color: #2867b2;
}

footer > a:not(:first-child) {
  margin-left: 10px;
}
</style>
