<template>
  <div id="app">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Vue from 'vue'
import SuiVue from 'semantic-ui-vue'
import 'semantic-ui-css/semantic.min.css'
import { router, routes } from '@/router/index'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Social from '@/config/social.json'

Vue.use(SuiVue)

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data: () => {
    return {
      social: Social
    }
  }
};

// Workaround for Github pages history mode
(function () {
  const redirect = sessionStorage.redirect
  delete sessionStorage.redirect

  if (redirect && redirect !== location.href) {
    history.replaceState(null, null, redirect)

    let routeName
    const pathName = new URL(redirect).pathname

    routes.forEach((route) => {
      if (route.path === pathName) {
        routeName = route.name
      }
    })

    if (typeof routeName !== 'undefined') {
      router.push({
        name: routeName
      })
    }
  }
})()
</script>
<style>
#app {
  text-align: center;
  color: #2c3e50;
}

code {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #000;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}
</style>
