<template>
  <div class="home">
    <p class="description">Hello, and welcome to my website!<br>My interests mostly include video games, development, and food :)</p>
  </div>
</template>
<script>
export default {
  name: 'Home'
}
</script>
<style>
.home {
  max-width: 90%;
  margin: 0 auto;
}
.home > .description {
  font-size: 19px;
}
</style>
