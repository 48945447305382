<template>
    <a @click='headHome'>The page requested does not exist. Click to go back to homepage.</a>
</template>
<script>
import router from '../router/index'

export default {
  name: 'PageNotFound',
  methods: {
    headHome: () => {
      router.push({
        name: 'Home'
      })
    }
  }
}
</script>
<style scoped>
a {
    font-size: 18px;
    color: red;
    cursor: pointer;
}
</style>
