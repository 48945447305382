<template>
  <header>
    <div id="nav">
      <router-link to="/" class="nav-link" exact>Home</router-link>
      <router-link to="/about" class="nav-link">About</router-link>
      <router-link to="/certifications" class="nav-link">Certifications</router-link>
      <router-link to="/blog" class="nav-link">Blog</router-link>
    </div>
</header>
</template>
<script>
export default {
  name: 'Header'
}
</script>
<style>
#nav a {
  font-size: 17px;
}

#nav a:not(:last-child)::after {
  content: '/';
  padding: 5px;
}

#nav {
  padding: 30px;
}

#nav a:hover {
  color: #42b983;
  transition: color 0.3s ease;
}

#nav a {
  font-weight: bold;
  color: #b9b9b9;
  text-decoration: none;
}

#nav a.router-link-exact-active,
#nav a.router-link-active {
  color: #42b983;
}

</style>
